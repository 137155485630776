<template>
    <div class="institutionalFilingDetail" v-loading="loading">
        <!--顶部面包靴-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/institutionalInformation/institutionalFiling' }" class="fs20">机构备案</el-breadcrumb-item>
            <el-breadcrumb-item class="fs20">{{ '查看' }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="content">
            <el-descriptions title="基本信息">
                <el-descriptions-item label="登记方式">{{ dataInfo.registrationMethod || '--' }}</el-descriptions-item>
                <el-descriptions-item label="是否完成民政局备案">
                    <el-radio-group v-model="dataInfo.isRecord">
                        <el-radio label="是" :disabled="dataInfo.isRecord !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.isRecord !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="未备案原因">其他</el-descriptions-item>
                <el-descriptions-item label="是否属于挂靠敬老院">
                    <el-radio-group v-model="dataInfo.isGeracomium">
                        <el-radio label="是" :disabled="dataInfo.isGeracomium !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.isGeracomium !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="挂靠类型">挂靠福利院</el-descriptions-item>
                <el-descriptions-item label="统一社会信用代码">{{ dataInfo.socialCode || '--' }}</el-descriptions-item>
                <el-descriptions-item label="单位名称">{{ dataInfo.name || '--' }}</el-descriptions-item>
                <el-descriptions-item label="单位建立日期">{{ dataInfo.creationDate || '--' }}</el-descriptions-item>
                <el-descriptions-item label="单位所属行政区划">{{ dataInfo.administrativeAreaCode || '--' }}</el-descriptions-item>
                <el-descriptions-item label="单位通信地址">{{ dataInfo.address || '--' }}</el-descriptions-item>
                <el-descriptions-item label="运营方式">{{ dataInfo.operationMode || '--' }}</el-descriptions-item>
            </el-descriptions>
            <div class="line"></div>
            <el-descriptions title="法人信息">
                <el-descriptions-item label="法人代表姓名">{{ dataInfo.legalName || '--' }}</el-descriptions-item>
                <el-descriptions-item label="法人代表证件类型">{{ dataInfo.documentType || '--' }}</el-descriptions-item>
                <el-descriptions-item label="法人代表证件号码">{{ dataInfo.idCard || '--' }}</el-descriptions-item>
                <el-descriptions-item label="法人登记机关">{{ dataInfo.registrationAuthority || '--' }}</el-descriptions-item>
                <el-descriptions-item label="法人代表联系方式">{{ dataInfo.legalPhone || '--' }}</el-descriptions-item>
                <el-descriptions-item label="法人性质">{{ dataInfo.legalPerson || '--' }}</el-descriptions-item>
            </el-descriptions>
            <div class="line"></div>
            <el-descriptions title="法人扩展信息一">
                <el-descriptions-item label="床位总数量(张)">{{ dataInfo.bedsSum || 0 }}</el-descriptions-item>
                <el-descriptions-item label="全托床位数量(张)">{{ dataInfo.totalCareBeds || 0 }}</el-descriptions-item>
                <el-descriptions-item label="日托床位数量(张)">{{ dataInfo.dayCareBeds || 0 }}</el-descriptions-item>
                <el-descriptions-item label="护理床位数量(张)">{{ dataInfo.nursingSum || 0 }}</el-descriptions-item>
                <el-descriptions-item label="全托护理床位数量(张)">{{ dataInfo.totalCareNursing || 0 }}</el-descriptions-item>
                <el-descriptions-item label="日托护理床位数量(张)">{{ dataInfo.dayCareNursing || 0 }}</el-descriptions-item>
                <el-descriptions-item label="建筑面积（㎡)">{{ dataInfo.builtUp || 0 }}</el-descriptions-item>
                <el-descriptions-item label="占地面积（㎡）">{{ dataInfo.floorArea || 0 }}</el-descriptions-item>
                <el-descriptions-item label="所处位置">{{ dataInfo.location || '--' }}</el-descriptions-item>
                <el-descriptions-item label="单位性质">其他</el-descriptions-item>
                <el-descriptions-item label="场所性质">{{ dataInfo.naturePlace || '--' }}</el-descriptions-item>
                <el-descriptions-item label="兴办主体">{{ dataInfo.subject || '--' }}</el-descriptions-item>
                <el-descriptions-item label="收费方式">{{ dataInfo.chargingMethod || '--' }}</el-descriptions-item>
                <el-descriptions-item label="护理费（最高）元/月">{{ dataInfo.nursingFeeMax || 0 }}</el-descriptions-item>
                <el-descriptions-item label="护理费（最低）元/月">{{ dataInfo.nursingFeeLow || 0 }}</el-descriptions-item>
                <el-descriptions-item label="床位费（最高）元/月">{{ dataInfo.bedFeeMax || 0 }}</el-descriptions-item>
                <el-descriptions-item label="床位费（最低）元/月">{{ dataInfo.bedFeeLow || 0 }}</el-descriptions-item>
                <el-descriptions-item label="膳食费（最高）元/月">{{ dataInfo.mealsMax || 0 }}</el-descriptions-item>
                <el-descriptions-item label="膳食费（最低）元/月">{{ dataInfo.mealsLow || 0 }}</el-descriptions-item>
                <el-descriptions-item label="押金/备用金（最高）元/月">0</el-descriptions-item>
                <el-descriptions-item label="月末收住老年人数">{{ dataInfo.elderlyResidentsSum || 0 }}</el-descriptions-item>
                <el-descriptions-item label="机构生存状态">{{ dataInfo.organizationSurvivalStatus || '--' }}</el-descriptions-item>
                <el-descriptions-item label="收住老人类型">{{ dataInfo.typeElderly || '--' }}</el-descriptions-item>
                <el-descriptions-item label="收住老人类型其他（说明）">{{ dataInfo.typeElderlyOther || '--' }}</el-descriptions-item>
                <el-descriptions-item label="享受政府扶持优惠政策内容代码">{{ dataInfo.policyCode || '--' }}</el-descriptions-item>
                <el-descriptions-item label="是否失能特困救助供养机构">
                    <el-radio-group v-model="dataInfo.isDisability">
                        <el-radio label="是" :disabled="dataInfo.isDisability !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.isDisability !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="是否为光荣院">
                    <el-radio-group v-model="dataInfo.isGlory">
                        <el-radio label="是" :disabled="dataInfo.isGlory !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.isGlory !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="是否投保养老机构责任险">
                    <el-radio-group v-model="dataInfo.isLiabilityInsure">
                        <el-radio label="是" :disabled="dataInfo.isLiabilityInsure !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.isLiabilityInsure !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="是否长护险定点机构">
                    <el-radio-group v-model="dataInfo.isInsurance">
                        <el-radio label="是" :disabled="dataInfo.isInsurance !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.isInsurance !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="是否采用会员制方式收费">
                    <el-radio-group v-model="dataInfo.isMember">
                        <el-radio label="是" :disabled="dataInfo.isMember !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.isMember !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="是否区域养老中心">
                    <el-radio-group v-model="dataInfo.isRegion">
                        <el-radio label="是" :disabled="dataInfo.isRegion !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.isRegion !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="区域">{{ dataInfo.region || '--' }}</el-descriptions-item>
                <el-descriptions-item label="是否提供居家上门服务">
                    <el-radio-group v-model="dataInfo.isProvided">
                        <el-radio label="是" :disabled="dataInfo.isProvided !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.isProvided !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="家庭养老床位（服务人次/月）">{{ dataInfo.homeCareBed || 0 }}</el-descriptions-item>
                <el-descriptions-item label="上门助餐（服务人次/月）">{{ dataInfo.helpMeals || 0 }}</el-descriptions-item>
                <el-descriptions-item label="上门助洁（服务人次/月）">{{ dataInfo.helpClean || 0 }}</el-descriptions-item>
                <el-descriptions-item label="上门助行（服务人次/月）">{{ dataInfo.walkingAid || 0 }}</el-descriptions-item>
                <el-descriptions-item label="上门助浴（服务人次/月）">{{ dataInfo.bathAid || 0 }}</el-descriptions-item>
                <el-descriptions-item label="上门康复（服务人次/月）">{{ dataInfo.recovery || 0 }}</el-descriptions-item>
                <el-descriptions-item label="上门助医（服务人次/月）">{{ dataInfo.medicalAssistance || 0 }}</el-descriptions-item>
                <el-descriptions-item label="巡访关爱（服务人次/月）">{{ dataInfo.visitingCare || 0 }}</el-descriptions-item>
                <el-descriptions-item label="其他居家上门服务（服务人次/月）">{{ dataInfo.homeService || 0 }}</el-descriptions-item>
                <el-descriptions-item label="是否提供社区养老服务">
                    <el-radio-group v-model="dataInfo.communityService">
                        <el-radio label="是" :disabled="dataInfo.communityService !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.communityService !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="日托（日间照料）（服务人次/月）">{{ dataInfo.dayCare || 0 }}</el-descriptions-item>
                <el-descriptions-item label="老年餐桌（食堂）（服务人次/月）">{{ dataInfo.elderlyDiningTable || 0 }}</el-descriptions-item>
                <el-descriptions-item label="社区文化娱乐（服务人次/月）">{{ dataInfo.cultureEntertainment || 0 }}</el-descriptions-item>
                <el-descriptions-item label="时间银行（志愿服务）（服务人次/月）">{{ dataInfo.timeBank || 0 }}</el-descriptions-item>
                <el-descriptions-item label="社区康复（服务人次/月）">{{ dataInfo.communityRehabilitation || 0 }}</el-descriptions-item>
                <el-descriptions-item label="其他社区养老服务">{{ dataInfo.otherServices || '--' }}</el-descriptions-item>
                <el-descriptions-item label="其他社区养老服务（服务人次/月）">{{ dataInfo.otherServicesPeople || 0 }}</el-descriptions-item>
                <el-descriptions-item label="是否开展医疗服务">
                    <el-radio-group v-model="dataInfo.isMedicalServices">
                        <el-radio label="是" :disabled="dataInfo.isMedicalServices !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.isMedicalServices !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="与医疗机构签约合作">{{ dataInfo.signingCooperation || '--' }}</el-descriptions-item>
                <el-descriptions-item label="内设医疗机构">{{ dataInfo.builtMedicalInstitutions || '--' }}</el-descriptions-item>
                <el-descriptions-item label="是否取得医疗机构执业许可证书">
                    <el-radio-group v-model="dataInfo.practiceLicense">
                        <el-radio label="是" :disabled="dataInfo.practiceLicense !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.practiceLicense !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="是否取得医疗保险定点医疗机构资格证书">
                    <el-radio-group v-model="dataInfo.qualification">
                        <el-radio label="是" :disabled="dataInfo.qualification !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.qualification !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="开展医疗服务方式">{{ dataInfo.serviceMode || '--' }}</el-descriptions-item>
                <el-descriptions-item label="医养结合">{{ dataInfo.medicineAndNursing || '--' }}</el-descriptions-item>
                <el-descriptions-item label="医院等级分类">{{ dataInfo.hospitalClassification || '--' }}</el-descriptions-item>
                <el-descriptions-item label="是否其他收费">
                    <el-radio-group v-model="dataInfo.otherCharges">
                        <el-radio label="是" :disabled="dataInfo.otherCharges !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.otherCharges !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="其他收费项目名称">其他</el-descriptions-item>
                <el-descriptions-item label="其他收费（最高）元/月">0</el-descriptions-item>
                <el-descriptions-item label="其他收费（最低）元/月">0</el-descriptions-item>
                <el-descriptions-item label="是否取得食品生产许可证">
                    <el-radio-group v-model="dataInfo.foodProductionLicense">
                        <el-radio label="是" :disabled="dataInfo.foodProductionLicense !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.foodProductionLicense !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="未取得食品生产许可证原因">其他</el-descriptions-item>
                <el-descriptions-item label="未取得食品生产许可证监管部门审核意见">其他</el-descriptions-item>
                <el-descriptions-item label="是否通过消防部门审验合格">
                    <el-radio-group v-model="dataInfo.passTheExamination">
                        <el-radio label="是" :disabled="dataInfo.passTheExamination !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.passTheExamination !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="未通过消防部门审验合格原因">其他</el-descriptions-item>
                <el-descriptions-item label="未通过消防部门审验监管部门审核意见">其他</el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="扩展信息二">
                <el-descriptions-item label="机构属性类别">{{ dataInfo.organizationAttributeCategory || '--' }}</el-descriptions-item>
                <el-descriptions-item label="机构（设施）级别">{{ dataInfo.organizationLevel || '--' }}</el-descriptions-item>
                <el-descriptions-item label="机构类别">{{ dataInfo.organizationType || '--' }}</el-descriptions-item>
                <el-descriptions-item label="机构编号">{{ dataInfo.institutionNumber || '--' }}</el-descriptions-item>
                <el-descriptions-item label="是否为连锁运营机构">
                    <el-radio-group v-model="dataInfo.operatingOrganization">
                        <el-radio label="是" :disabled="dataInfo.operatingOrganization !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.operatingOrganization !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="是否主机构">
                    <el-radio-group v-model="dataInfo.isHostStructure">
                        <el-radio label="是" :disabled="dataInfo.isHostStructure !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.isHostStructure !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="是否特困失能照护中心">
                    <el-radio-group v-model="dataInfo.careCenter">
                        <el-radio label="是" :disabled="dataInfo.careCenter !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.careCenter !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="是否为新建特困失能照护中心">
                    <el-radio-group v-model="dataInfo.incapacitatedCare">
                        <el-radio label="是" :disabled="dataInfo.incapacitatedCare !== '是'">是</el-radio>
                        <el-radio label="否" :disabled="dataInfo.incapacitatedCare !== '否'">否</el-radio>
                    </el-radio-group>
                </el-descriptions-item>
                <el-descriptions-item label="不是新建特困失能照护中心原因">敬老院改造成的</el-descriptions-item>
            </el-descriptions>
            <div class="line"></div>
            <el-descriptions title="地图信息">
                <el-descriptions-item label="经度">{{ dataInfo.longitude || '--' }}</el-descriptions-item>
                <el-descriptions-item label="纬度">{{ dataInfo.latitude || '--' }}</el-descriptions-item>
            </el-descriptions>
            <div id="map" v-show="dataInfo.longitude && dataInfo.latitude"></div>
            <span v-show="!dataInfo.longitude && !dataInfo.latitude">暂无地图信息</span>
        </div>
    </div>
</template>

<script>
import { institutionalFilingDetails } from '@/api/institutionalManage'
export default {
    name: 'institutionalFilingDetail',
    data() {
        return {
            loading: false,
            dataInfo: {},
            point: [106.55495, 29.744899]
        }
    },
    mounted() {
        // this.initMap()
        this.getData()
    },
    methods: {
        initMap() {
            const map = new AMap.Map('map', {
                resizeEnable: true,
                zoom: 12,
                center: this.point //中心点坐标
            }) //初始化地图
            const marker = new AMap.Marker({
                position: this.point // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            })
            map.add([marker])
            map.setFitView()
        },
        getData() {
            this.loading = true
            institutionalFilingDetails({ id: Number(this.$route.query.id) })
                .then(res => {
                    console.log(res)
                    this.dataInfo = res.data
                    this.point = [res.data.longitude, res.data.latitude]
                    this.initMap()
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.institutionalFilingDetail {
    .fs20 {
        font-size: 14px;
    }
    .content {
        margin-top: 20px;
        background-color: #ffffff;
        padding: 20px;
        .line {
            height: 1px;
            background: linear-gradient(to right, #aaa 0%, #aaa 50%, transparent 0%);
            background-size: 20px 1px;
            background-repeat: repeat-x;
            margin: 20px 0;
        }
        #map {
            width: 100%;
            height: 300px;
        }
    }
}
</style>
